<template>
  <div id="accountRecharge">
    <div class="search-form ant-round-form">
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_004') }}: </span>
        <a-select
          v-model:value="searchQuery.currency"
          allow-clear
          show-search
          option-label-prop="label"
          option-filter-prop="label"
          @change="loadRechargeFlow(1)"
        >
          <a-select-option
            v-for="item in curList"
            :key="item.currency"
            :value="item.currency"
            :label="`${item.currency} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.currency)" class="select-img-country">
            <span>{{ item.currency }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_005') }}: </span>
        <a-select
          v-model:value="searchQuery.bankCode"
          allow-clear
          show-search
          @change="loadRechargeFlow(1)"
        >
          <a-select-option
            v-for="bank in bankList"
            :key="bank.code"
            :value="bank.code"
          >{{ bank.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_006') }}: </span>
        <a-input
          v-model:value="searchQuery.batchNo"
          allow-clear
          @pressEnter="loadRechargeFlow(1)"
          @blur="loadRechargeFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_007') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.dateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadRechargeFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_008') }}: </span>
        <a-row style="width: 100%">
          <a-col :span="10">
            <a-input-number
              v-model:value="searchQuery.minAmount"
              style="width: 100%"
              :controls="false"
              @pressEnter="loadRechargeFlow(1)"
              @blur="loadRechargeFlow(1)"
            />
          </a-col>
          <a-col :span="4" style="text-align: center">-</a-col>
          <a-col :span="10">
            <a-input-number
              v-model:value="searchQuery.maxAmount"
              style="width: 100%"
              :controls="false"
              @pressEnter="loadRechargeFlow(1)"
              @blur="loadRechargeFlow(1)"
            />
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="table-part">
      <a-table
        :scroll="{ x: 1200 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="rechargeList"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'edit'">
            <a-button
              v-if="themeData.electronicReceipt"
              shape="round"
              size="small"
              class="btn-primary-empty-0"
              @click="downRechargeReceipt(record.batchNo)"
            >{{ $t('pages_account_023') }}</a-button>
          </template>
        </template>
      </a-table>
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject } from 'vue'
import { _account } from '@/api'
import i18n from '@/locale'
import TablePage from '@/components/table-page'
import { getCountryLogo, parseMoney, getTradeStatusName } from '@/util'
export default {
  name: 'Flow',
  components: {
    'table-page': TablePage
  },
  setup() {
    const state = reactive({
      themeData: inject('$themeData'),
      loading: true,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        currency: '',
        bankCode: '',
        batchNo: '',
        minAmount: null,
        maxAmount: null,
        dateOpts: []
      },
      total: 0,
      curList: [],
      bankList: [],
      rechargeList: [],
      columns: [
        { key: 'batchNo', dataIndex: 'batchNo', title: i18n.global.t('pages_account_006'), width: 200 },
        { key: 'curType', dataIndex: 'curType', title: i18n.global.t('pages_account_004'), width: localStorage.getItem('locale-language') === 'en' ? 165 : 120 },
        { key: 'amount', dataIndex: 'amountText', title: i18n.global.t('pages_account_008'), width: 150 },
        { key: 'bank', dataIndex: 'bank', title: i18n.global.t('pages_account_005'), width: 200, ellipsis: true },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_account_021'), width: 150 },
        { key: 'updateTime', dataIndex: 'updateTime', title: i18n.global.t('pages_account_022'), width: 180 },
        { key: 'edit', title: i18n.global.t('common_text_001'), fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 250 : 150, align: 'center' }
      ]
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadRechargeFlow()
    }

    const exportRecharge = async () => {
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _account.exportRecharge(query)
    }

    const downRechargeReceipt = (batchNo) => {
      _account.downRechargeReceipt({ batchNo })
    }

    const loadRechargeSup = async () => {
      const res = await _account.getRechargeSup()
      if (res.data.succ) {
        const { data } = res.data
        state.curList = data.currencyList
        state.bankList = [{ name: data.bankName, code: data.bankCode }]
      }
    }
    const loadRechargeFlow = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _account.getRechargeRecord(query)
      if (res.data.succ) {
        const { data } = res.data
        state.rechargeList = data.list.map(item => {
          return Object.assign({}, item, {
            statusText: getTradeStatusName(item.status, 'R'),
            updateTime: item.updateTime || '-',
            tradeAmountText: parseMoney(item.tradeAmount, item.curType),
            amountText: parseMoney(item.amount, item.curType)
          })
        })
        state.total = data.total
      }
      state.loading = false
    }

    const initPage = () => {
      loadRechargeSup()
      loadRechargeFlow()
    }

    initPage()

    return {
      updatePage,
      exportRecharge,
      getCountryLogo,
      loadRechargeFlow,
      downRechargeReceipt,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#accountRecharge {
  .search-form {
    margin-top: 8px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>
