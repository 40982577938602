<template>
  <div id="accountPayment">
    <div class="search-form ant-round-form">
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_053') }}: </span>
        <a-input
          v-model:value="searchQuery.batchNo"
          allow-clear
          @pressEnter="loadPayFlow(1)"
          @blur="loadPayFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_054') }}: </span>
        <a-select
          v-model:value="searchQuery.status"
          allow-clear
          @change="loadPayFlow(1)"
        >
          <a-select-option
            v-for="item in statusList"
            :key="item.code"
            :value="item.code"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_055') }}: </span>
        <a-select
          v-model:value="searchQuery.payType"
          allow-clear
          @change="loadPayFlow(1)"
        >
          <a-select-option
            v-for="item in payTypeList"
            :key="item.code"
            :value="item.code"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_026') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.submitDateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadPayFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_056') }}: </span>
        <a-input
          v-model:value="searchQuery.payee"
          allow-clear
          @pressEnter="loadPayFlow(1)"
          @blur="loadPayFlow(1)"
        />
      </div>
      <div v-if="isOpenPobo" class="part flex-align">
        <span class="part-label">{{ $t('pages_account_057') }}: </span>
        <a-select
          v-model:value="searchQuery.isOpenPobo"
          allow-clear
          @change="loadPayFlow(1)"
        >
          <a-select-option
            v-for="item in isSameList"
            :key="item.code"
            :value="item.code"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_058') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.finishDateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadPayFlow(1)"
        />
      </div>
    </div>
    <div class="table-part">
      <a-table
        :scroll="{ x: 1200 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="payFlows"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'batchNo'">
            <a @click="showDetail(record)">{{ record.batchNo }}</a>
          </template>
          <template v-if="column.key === 'edit'">
            <a-button
              shape="round"
              size="small"
              class="btn-primary-empty-0"
              @click="showDetail(record)"
            >{{ $t('pages_account_063') }}</a-button>
          </template>
        </template>
      </a-table>
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
    <a-card
      v-if="visible"
      style="margin-top: 24px"
      hoverable
      :title="`${record.batchNo} - ${$t('pages_account_076')}`"
      :body-style="{padding: 0}"
    >
      <template #extra>
        <a-dropdown>
          <a-button type="primary">{{ $t('pages_account_077') }}</a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click="downAllFile('xls')">xls{{ $t('pages_account_078') }}</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="downAllFile('xls')">xlsx{{ $t('pages_account_078') }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-dropdown v-if="record.status === '40'">
          <a-button type="primary" danger style="margin-left: 12px">{{ $t('pages_account_080') }}</a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a @click="downFailFile('xls')">xls{{ $t('pages_account_078') }}</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="downFailFile('xls')">xlsx{{ $t('pages_account_078') }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-button
          v-if="themeData.electronicReceipt && record.payType === 'I'"
          type="primary"
          style="margin-left: 12px"
          @click="downInfoReceiptBatch"
        >{{ $t('pages_account_079') }}</a-button>
      </template>
      <a-table
        :scroll="{ x: 1200 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="detailColumns"
        :data-source="detailList"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'edit'">
            <a-button
              v-if="themeData.electronicReceipt && record.singlePayStatus === '01'"
              size="small"
              type="link"
              shape="round"
              class="btn-primary-empty-0"
              @click="downInfoReceipt(record)"
            >{{ $t('pages_account_023') }}</a-button>
          </template>
        </template>
      </a-table>
      <table-page
        :total="pageOpts.total"
        :page-opts="pageOpts"
        @update-page="updatePayPage"
      />
    </a-card>
  </div>
</template>
<script>
import TablePage from '@/components/table-page'
import { reactive, toRefs, computed } from 'vue'
import { _account, _pay } from '@/api'
import { message } from 'ant-design-vue'
import { formatColumns, parseMoney, getAttrStatusList, getTradeStatusName } from '@/util'
import i18n from '@/locale'
export default {
  name: 'AccountPayment',
  components: {
    'table-page': TablePage
  },
  props: {
    themeData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup () {
    const state = reactive({
      loading: true,
      detailLoading: true,
      visible: false,
      isOpenPobo: false,
      record: {},
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        batchNo: '',
        status: '',
        payType: '',
        submitDateOpts: [],
        payee: '',
        isOpenPobo: '',
        finishDateOpts: []
      },
      pageOpts: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      total: 0,
      payFlows: [],
      detailList: [],
      detailColumns: [],
      columns: [
        { key: 'batchNo', title: i18n.global.t('pages_account_062'), width: 220 },
        { key: 'tradeCount', dataIndex: 'tradeCount', title: i18n.global.t('pages_account_060'), width: localStorage.getItem('locale-language') === 'en' ? 180 : 100 },
        { key: 'tradeCur', dataIndex: 'tradeCur', title: i18n.global.t('pages_account_036'), width: localStorage.getItem('locale-language') === 'en' ? 180 : 120 },
        { key: 'tradeAmount', dataIndex: 'tradeAmountText', title: i18n.global.t('pages_account_037'), width: localStorage.getItem('locale-language') === 'en' ? 180 : 150 },
        { key: 'tradeType', dataIndex: 'tradeTypeText', title: i18n.global.t('pages_account_061'), width: localStorage.getItem('locale-language') === 'en' ? 180 : 120 },
        { key: 'payType', dataIndex: 'payTypeText', title: i18n.global.t('pages_account_055'), width: localStorage.getItem('locale-language') === 'en' ? 180 : 120 },
        { key: 'payee', dataIndex: 'payeeText', title: i18n.global.t('pages_account_056'), width: 200, ellipsis: true },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_account_054'), width: localStorage.getItem('locale-language') === 'en' ? 150 : 120 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_account_026'), width: 200 },
        { key: 'payTime', dataIndex: 'payTime', title: i18n.global.t('pages_account_058'), width: 200 },
        { key: 'edit', title: i18n.global.t('common_text_001'), fixed: 'right', width: 100, align: 'center' }
      ]
    })

    state.statusList = computed(() => {
      return getAttrStatusList('I')
    })

    state.payTypeList = computed(() => {
      return getAttrStatusList('payType').filter(item => item.code !== 'EI')
    })

    state.isSameList = computed(() => {
      return getAttrStatusList('isSameAccountName')
    })

    const showDetail = async (record) => {
      state.record = record
      state.detailLoading = true
      state.visible = true
      const { pageNum, pageSize } = state.pageOpts
      const query = {
        pageNum,
        pageSize,
        batchNo: record.batchNo,
        transactionNo: record.transactionNo || '',
        isApprove: true
      }
      if (record.payType === 'I') {
        const res = await _account.getCNYPayConfirmDetailList(query)
        if (res.data.succ) {
          state.detailColumns = [
            { key: 'merSingleNo', dataIndex: 'merSingleNo', title: i18n.global.t('pages_account_064'), width: 200 },
            { key: 'currency', dataIndex: 'currency', title: i18n.global.t('pages_account_065'), width: localStorage.getItem('locale-language') === 'en' ? 150 : 120 },
            { key: 'amount', dataIndex: 'amountText', title: i18n.global.t('pages_account_066'), width: localStorage.getItem('locale-language') === 'en' ? 150 : 120 },
            { key: 'subject', dataIndex: 'subjectText', title: i18n.global.t('pages_account_067'), width: 120 },
            { key: 'payeeName', dataIndex: 'payeeName', title: i18n.global.t('pages_account_068'), width: 180, ellipsis: true },
            { key: 'tradeCode', dataIndex: 'tradeCodeText', title: i18n.global.t('pages_account_061'), width: 150 },
            { key: 'bankName', dataIndex: 'bankName', title: i18n.global.t('pages_account_069'), width: 180, ellipsis: true },
            { key: 'bankDetailAddr', dataIndex: 'bankDetailAddr', title: i18n.global.t('pages_account_070'), width: 200, ellipsis: true },
            { key: 'idNo', dataIndex: 'idNo', title: i18n.global.t('pages_account_071'), width: 200 },
            { key: 'mobile', dataIndex: 'mobile', title: i18n.global.t('pages_account_072'), width: 200 },
            { key: 'tradeUse', dataIndex: 'tradeUse', title: i18n.global.t('pages_account_073'), width: 150, ellipsis: true },
            { key: 'tradeRemark', dataIndex: 'tradeRemark', title: i18n.global.t('pages_account_074'), width: 200, ellipsis: true },
            { key: 'failReason', dataIndex: 'failReason', title: i18n.global.t('pages_account_075'), width: 200, ellipsis: true },
            { key: 'singlePayStatus', dataIndex: 'singlePayStatusText', title: i18n.global.t('pages_account_054'), width: localStorage.getItem('locale-language') === 'en' ? 150 : 120 },
            { key: 'edit', title: i18n.global.t('common_text_001'), fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 250 : 150, align: 'center' }
          ]
          state.detailList = res.data.data.list.map(item => {
            return Object.assign({}, item, {
              tradeCodeText: getTradeStatusName(item.tradeCode, 'tradeCode'),
              subjectText: getTradeStatusName(item.subject, 'subjectType'),
              singlePayStatusText: getTradeStatusName(item.singlePayStatus, 'singlePayStatus'),
              tradeUseText: getTradeStatusName(item.tradeUse, 'tradeUse'),
              amountText: parseMoney(item.amount)
            })
          })
          state.pageOpts.total = res.data.data.total
        }
      } else {
        const res = await _pay.getForeignPayConfirmDetailList(query)
        if (res.data.succ) {
          const { pageInfo, titles } = res.data.data
          const columns = formatColumns(titles, pageInfo.list).map(item => {
            if (item.key === 'singlePayStatus') {
              return Object.assign({}, item, {
                dataIndex: 'singlePayStatusText'
              })
            }
            if (item.key === 'isOpenPobo') {
              return Object.assign({}, item, {
                dataIndex: 'isOpenPoboText'
              })
            }
            return Object.assign({}, item)
          })
          columns.push({ key: 'edit', title: i18n.global.t('common_text_001'), fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 250 : 150, align: 'center' })
          state.detailColumns = columns

          state.detailList = pageInfo.list.map(item => {
            return Object.assign({}, item, {
              payerType: getTradeStatusName(item.payerType, 'subjectType'),
              bankAcctType: getTradeStatusName(item.bankAcctType, 'subjectType'),
              amount: parseMoney(item.amount),
              singlePayStatusText: getTradeStatusName(item.singlePayStatus, 'singlePayStatus'),
              isOpenPoboText: getTradeStatusName(String(item.isOpenPobo), 'isSameAccountName')
            })
          })
          state.pageOpts.total = pageInfo.total
        }
      }
      const el = document.getElementById('menuContent')
      el.scrollTop = el.scrollHeight
      state.detailLoading = false
    }

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadPayFlow()
    }

    const updatePayPage = (page, pageSize) => {
      state.pageOpts.pageNum = page
      state.pageOpts.pageSize = pageSize
      showDetail(state.record)
    }

    const downAllFile = async (fileType) => {
      message.info(i18n.global.t('common_text_023'))
      const data = {
        fileType,
        batchApplyNo: state.record.batchNo,
        merSingleNo: state.record.transactionNo || '',
        isApprove: 'true'
      }
      if (state.record.payType === 'I') await _account.downPayDetailCny(data)
      else await _account.dowPayDetailForeign(data)
    }

    const downFailFile = async (fileType) => {
      message.info(i18n.global.t('common_text_023'))
      const data = {
        fileType,
        batchApplyNo: state.record.batchNo,
        merSingleNo: state.record.transactionNo || '',
        isApprove: 'true'
      }
      if (state.record.payType === 'I') {
        await _account.downPayDetailFailCny(data)
      } else {
        data.approveStatus = '00'
        await _account.dowPayDetailForeign(data)
      }
    }

    const downPayReceipts = async () => {
      await _account.downPayReceipts(getParams(0))
    }

    const downInfoReceipt = async (record) => {
      message.info(i18n.global.t('common_text_023'))
      const data = {
        applyNo: record.applyNo || '',
        merSingleNo: record.merSingleNo || ''
      }
      if (state.record.payType === 'I') {
        data.batchNo = record.batchNo
        await _account.downPayFlowSeal(data)
      } else {
        data.batchNo = record.batchApplyNo
        await _account.downOutPayReceipt(data)
      }
    }

    const downInfoReceiptBatch = async () => {
      const data = {
        batchNo: state.record.batchNo,
        merSingleNo: state.record.transactionNo || ''
      }
      const res = await _account.checkBatchPayReceipt(data)
      if (res.data.succ) {
        message.info(i18n.global.t('common_text_023'))
        await _account.downBatchPayReceipt(data)
      } else {
        message.error(i18n.global.t(res.data.resMsg))
      }
    }

    const exportPayFlow = async () => {
      await _account.exportPayFlow(getParams(0))
    }

    const loadMerchantInfo = async () => {
      const res = await _pay.findByMerchantKycEnrol()
      if (res.data.succ) {
        const { data } = res.data
        state.isOpenPobo = data.products ? data.products.indexOf('POBO') !== -1 : false
      }
    }

    const loadPayFlow = async (pageNum) => {
      state.loading = true
      const res = await _account.getPayFlow(getParams(pageNum))
      if (res.data.succ) {
        state.payFlows = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            statusText: getTradeStatusName(item.status, 'I'),
            tradeTypeText: getTradeStatusName(item.tradeType, 'subjectType'),
            payeeText: item.payee && item.payee.split('/').length > 2 ? `${item.payee.split('/')[0]}/···` : item.payee,
            payTypeText: getTradeStatusName(item.payType, 'payType'),
            tradeAmountText: parseMoney(item.tradeAmount, item.tradeCur)
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
      state.visible = false
    }

    const getParams = (pageNum) => {
      if (!state.searchQuery.submitDateOpts) state.searchQuery.submitDateOpts = []
      if (!state.searchQuery.finishDateOpts) state.searchQuery.finishDateOpts = []

      if (pageNum) state.searchQuery.pageNum = pageNum

      const { submitDateOpts, finishDateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: submitDateOpts[0] ? `${submitDateOpts[0]} 00:00:00` : '',
        endDate: submitDateOpts[1] ? `${submitDateOpts[1]} 23:59:59` : '',
        payBeginDate: finishDateOpts[0] ? `${finishDateOpts[0]} 00:00:00` : '',
        payEndDate: finishDateOpts[1] ? `${finishDateOpts[1]} 23:59:59` : ''
      })

      return query
    }

    const init = async () => {
      loadMerchantInfo()
      loadPayFlow()
    }

    init()

    return {
      updatePage,
      updatePayPage,
      showDetail,
      downPayReceipts,
      downInfoReceipt,
      downInfoReceiptBatch,
      downAllFile,
      downFailFile,
      exportPayFlow,
      loadPayFlow,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#accountPayment {
  .search-form {
    margin-top: 8px;
    .part-label {
      width: 145px;
    }
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>
